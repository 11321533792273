import React, {Component} from 'react';
import { connect } from 'react-redux';
import {withStyles} from "@material-ui/core";
import {bindActionCreators} from "redux";
import {actionCreators as offerActions} from "../../store/Offer";
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import history from "../../helpers/historyHelper";
import Moment from "react-moment";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

const styles = theme => ({
    paper: {
        overflow: 'hidden',
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    newOffer: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '40px 26px',
    },
    itemRow: {
        cursor: "pointer",
        "&:hover": {
            background: "#efefef"
        }
    }
});



class MyCases extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAssigningUser: false,
            assignCaseworkerForSaving: {
                offerId: null,
                userId: null,
            },
        };
    }
    
    openAssignCaseToUser = (offerId) => (event) => {
        event.stopPropagation();
        this.setState(prevState => ({
            isAssigningUser: true,
            assignCaseworkerForSaving: {
                ...prevState.assignCaseworkerForSaving,
                offerId: offerId
            }
        }));
    };

    assignCaseToUser = () => {
        console.log(this.state);
        this.props.offerActions.assignCaseWorker(this.state.assignCaseworkerForSaving, this.assignCaseToUserSuccess);
    };

    assignCaseToUserSuccess = () => {
        this.setState({
            isAssigningUser: false,
            assignCaseworkerForSaving: {
                userId: null,
                offerId: null
            }
        });
        this.props.offerActions.getOffers();
    };

    handleInputChangeAssignUserToCase = () => event => {
        console.log(event);
        const target = event.target;
        if(target !== null) {
            const value = target.value;
            this.setState(prevState => ({
                assignCaseworkerForSaving: {
                    ...prevState.assignCaseworkerForSaving,
                    userId: value    
                }
            }));
        }
    };
    
    render() {
        const {classes} = this.props;
        const {isAssigningUser} = this.state;

        let user = JSON.parse(localStorage.getItem('user'));
        return (
            <div>
                <Paper className={classes.paper}>
                    <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                        <Toolbar>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs>
                                    <h3>Mine saker</h3>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.contentWrapper}>
                        {this.props.offer.offers.length !== 0 &&
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell algin="left">Ref.Nr</TableCell>
                                    <TableCell align="left">Produkt</TableCell>
                                    <TableCell align="left">Status</TableCell>
                                    <TableCell align="left">Organisasjon</TableCell>
                                    <TableCell align="left">Registrert av</TableCell>
                                    <TableCell align="left">Registrert dato</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.offer.offers.filter(function(x) {return x.offerStatusId !== 5 && (x.offerCaseWorkerUserId === user.userId || x.offerAccountant1UserId === user.userId || x.offerAccountant2UserId === user.userId);}).map(row => (
                                    <TableRow className={ classes.itemRow } key={row.offerId} onClick={() => {history.push(`/offer/${row.offerId}`)}}>
                                        <TableCell align="left">{row.offerId}</TableCell>
                                        <TableCell align="left">{row.title}</TableCell>
                                        <TableCell align="left">{row.offerStatus.status}</TableCell>
                                        <TableCell align="left">{row.organization.name}</TableCell>
                                        <TableCell align="left">{row.registeredByUser.fullname}</TableCell>
                                        <TableCell align="left"><Moment>{row.registeredDate}</Moment></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        }

                        {this.props.offer.offers.length === 0 &&
                        <Typography color="textSecondary" align="center">
                            Ingen saker tilgjengelig
                        </Typography>
                        }
                    </div>
                </Paper>
                
            </div>
        )
    };

    componentDidMount() {
        this.props.offerActions.getOffers();
        this.props.offerActions.getCaseWorkers();
    }
    componentWillUnmount() {
        
    }
}

function mapDispatchToProps(dispatch) {
    return {
        offerActions: bindActionCreators(offerActions, dispatch),
    }
}

function mapStateToProps(state) {
    return {
        offer: state.offer,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyCases));
