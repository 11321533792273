import React, {Component, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {createStyles, withStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FileUploader from "../Common/FileUploader";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import {bindActionCreators} from "redux";
import {actionCreators as offerActions} from "../../store/Offer";
import {actionCreators as equipmentActions} from "../../store/Equipment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Collapse from "@material-ui/core/Collapse";
import Product from "./parts/offer/Product";
import Moment from 'react-moment';
import 'moment-timezone';
import OfferLog from "./parts/offer/OfferLog";
import ErrorIcon from '@material-ui/icons/Error';
import Deviation from "./Modal/Deviation";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import ChangeOfferStatus from "./Modal/ChangeOfferStatus";
import {makeStyles} from "@material-ui/core/styles";
import DeviationNotice from "./parts/offer/DeviationNotice";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import DeleteIcon from '@material-ui/icons/Delete';
import OfferEvaluationList from "./parts/offer/OfferEvaluationList";
import OrganizationDeclarations from "./Organization/OrganizationDeclarations";

const useStyles = makeStyles((theme) => createStyles({
    checkListItem: {
        lineHeight: "40px",
        padding: "12px 12px",
        borderBottom: "1px solid lightgray",
        fontWeight: "normal",
        fontSize: "14px",
        "&:last-child": {
            borderBottom: "none"
        },
        "&:hover": {
            background: "rgba(240,240,240,0.4)"
        }
    },
    toggleButton: {
        "&.Mui-selected&:nth-child(1)": {
            background: "lightgreen",
            color: "gray"
        },
        "&.Mui-selected&:nth-child(2)": {
            background: "lightcoral",
            color: "white"
        }
    },
    paper: {
        overflow: 'hidden',
        margin: theme.spacing(3, 0)
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '26px 26px',
    },
    sendBtn: {
        marginBottom: 20
    },
    deviationBtn: {
        "&:hover": {
            color: "#d80000"
        }
    }
}));

let timeout = null;

const Offer = ({stateOffer, match, offerActions, equipmentActions}) => {
    let initialFilesForSavingState = { isAddingFiles: false, filesForSaving: { tempFiles: [], category: null }};
    const [isChangingOfferStatus, setIsChangingOfferStatus] = useState(false);
    const [filesForSaving, setFilesForSaving] = useState(initialFilesForSavingState);
    const [deviationForSaving, setDeviationForSaving] = useState({isAddingDeviation: false, deviationForSaving: {}});
    
    const [offer, setOffer] = useState({});
    const [equipments, setEquipments] = useState([]);
    
    useEffect(() => {
        console.log("stateoffer", stateOffer.offer);
        setOffer(stateOffer.offer);
        setEquipments(stateOffer.offer.equipments);
    }, [stateOffer.offer]);
    
    useEffect(() => {
            const {offerId} = match.params;
            offerActions.getOffer(offerId);
            equipmentActions.getCategories();
            offerActions.getFiles(offerId);
            offerActions.getFileCategories();
    }, []);
    
    const handleInputChangeOffer = (wait) => event => {
        const target = event.target;
        if(target !== null) {
            let name = event.target.name;
            const value = target.type === 'checkbox' ? target.checked : target.type === 'number' ? parseFloat(target.value) : target.value;
            
            setOffer({...offer, [name]: value });
        }
        
        let updateOffer = offerActions.updateOffer;
        let offerForSaving = {...offer};
        if(wait) {
            clearTimeout(timeout);
            timeout = setTimeout(function () {
                updateOffer(offerForSaving);
            }, 1000);
        } else {
            clearTimeout(timeout);
            updateOffer(offerForSaving);
        }
    };
    
    const handleChangeCheckListItem = (name) => (event, newValue) => {
        let newOffer = {...offer, [name]: Boolean(Number(newValue)) };
        setOffer(newOffer);
        console.log(name, newValue);
        
        let updateOffer = offerActions.updateOffer;
        clearTimeout(timeout);
        timeout = setTimeout(function () {
            updateOffer(newOffer);
        }, 1000);
        
    };
    
    
    const handleChangeNewFileCategory = event => {
        if(event.target === null) return;
        const { value } = event.target;
        setFilesForSaving({ ...filesForSaving, filesForSaving: { ...filesForSaving.filesForSaving, category: value }});
    };

    const saveFiles = (offerId) => {
        offerActions.saveFiles(offerId, filesForSaving.filesForSaving.category, filesForSaving.filesForSaving.tempFiles.map(item => item.serverId), saveFilesSuccess);
    };

    const saveFilesSuccess = () => {
        const {offerId} = match.params;
        offerActions.getFiles(offerId);
        setFilesForSaving(initialFilesForSavingState);
    };

    const reloadOffer = (offerId) => {
        offerActions.getOffer(offerId)
    };

    const handleDeliverOffer = (offerId) => {
        if(!window.confirm("Er du sikker på at du vil sende inn tilbudet?")) return;
        offerActions.completeOffer(offerId, reloadOffer);
    };

    const openChangeOfferStatusModal = () => {
        setIsChangingOfferStatus(true);
    };

    const closeChangeOfferStatusModal = (event, loadOffer = null) => {
        setIsChangingOfferStatus(false);
        if(loadOffer) {
            offerActions.getOffer(offer.offerId);
        }
    };

    const openDeviationModal = (offerId, type) => {
        setDeviationForSaving({ isAddingDeviation: true, deviationForSaving: { offerId: offerId, deviationTypeId: type } });
    };

    const closeDeviationModal = (event, loadOffer = null) => {
        setDeviationForSaving({...deviationForSaving, isAddingDeviation: false });
        
        if(loadOffer) {
            offerActions.getOffer(offer.offerId);
        }
    };

    const openCloseDeviationModal = (deviationId) => {
        setDeviationForSaving({isAddingDeviation: true, deviationForSaving: { deviationId: deviationId }});
    };

    const deleteFile = (e, viewToken) => {
        e.stopPropagation();
        if(!window.confirm("Er du sikker?")) return;
        offerActions.deleteFile(viewToken, deleteFileSuccess);
    };
    
    const deleteFileSuccess = () => {
        const {offerId} = match.params;
        offerActions.getFiles(offerId);
    };
    
    const createNewEvaluation = () => {
        const {offerId} = match.params;
        offerActions.createNewEvaluation(offerId, onCreateNewEvaluationComplete);
    };
    
    const onCreateNewEvaluationComplete = ({offerId, offerEvaluationId}) => {
        window.open(`/Offer/Evaluation/${offerId}/${offerEvaluationId}`, "_blank");
    };

    const classes = useStyles();
    const { files, fileCategories } = stateOffer;

    let user = JSON.parse(localStorage.getItem('user'));
    let isAdmin = user.organizationId === "" && (user.role === "SuperAdmin" || user.role === "Admin" || user.role === "Normal");

    let disableFields = (offer && (offer.offerStatusId !== 1 && offer.offerStatusId !== 9));
    return (
        <div>
            <Grid container>
                <Grid item xs>
                    <h1 style={{marginTop: 0}}>Registrering av produkt/utstyr</h1>
                </Grid>
                <Grid item>
                    {isAdmin && <Button onClick={openChangeOfferStatusModal} variant="contained" color="primary">Sett ny status</Button>}
                </Grid>
            </Grid>
            <Deviation deviationData={deviationForSaving.deviationForSaving} isOpen={deviationForSaving.isAddingDeviation} closeDeviationModal={closeDeviationModal} />
            {offer && offer.offerId && <ChangeOfferStatus offerId={offer.offerId} isOpen={isChangingOfferStatus} closeChangeOfferStatusModal={closeChangeOfferStatusModal} />}

            <Paper className={classes.paper}>
                <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                    <Toolbar>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs>
                                <h3>Referanseperson i Lerøy</h3>
                            </Grid>
                            <Grid item>
                                {isAdmin && <IconButton className={classes.deviationBtn} onClick={() => openDeviationModal(offer.offerId, 1)}>
                                    <ErrorIcon />
                                </IconButton>}
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                    <DeviationNotice deviations={offer?.deviations} deviationType={1} openCloseDeviationModal={openCloseDeviationModal} disableFields={disableFields} />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl className={ classes.formControl } fullWidth={true}>
                                <TextField
                                    name="refPersonName"
                                    label="Navn"
                                    onChange={handleInputChangeOffer(true)}
                                    onBlur={handleInputChangeOffer(false)}
                                    value={offer?.refPersonName || ''}
                                    variant="outlined"
                                    disabled={disableFields}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className={ classes.formControl } fullWidth={true}>
                                <TextField
                                    name="refPersonEmail"
                                    label="Epost"
                                    onChange={handleInputChangeOffer(true)}
                                    onBlur={handleInputChangeOffer(false)}
                                    value={offer?.refPersonEmail || ''}
                                    variant="outlined"
                                    disabled={disableFields}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            </Paper>


            <Paper className={classes.paper}>
                <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                    <Toolbar>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs>
                                <h3>Produkt</h3>
                            </Grid>
                            <Grid item>
                                {isAdmin && <IconButton className={classes.deviationBtn} onClick={() => openDeviationModal(offer.offerId, 3)}>
                                    <ErrorIcon />
                                </IconButton>}
                            </Grid>

                        </Grid>
                    </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                    <DeviationNotice deviations={offer?.deviations} deviationType={3} openCloseDeviationModal={openCloseDeviationModal} disableFields={disableFields} />
                    <Product
                        equipments={equipments}
                        setEquipments={setEquipments}
                        disableFields={disableFields}/>
                </div>
            </Paper>


            <Paper className={classes.paper}>
                <Collapse in={filesForSaving.isAddingFiles}>
                    <div className={classes.contentWrapper}>

                        <div>
                            {filesForSaving.isAddingFiles &&
                            <FileUploader
                                addedFiles={filesForSaving.filesForSaving.tempFiles}
                                acceptedFileTypes={["image/*", "application/*", "text/*"]}
                                isUploading={(yey) => console.log(yey)}/>
                            }
                        </div>
                        <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
                            <InputLabel id="select-equipmentcategory-label">
                                Filkategori
                            </InputLabel>
                            <Select
                                id="select-filkategori-outlined"
                                name='filkategori'
                                value={filesForSaving.filesForSaving.category || ''}
                                onChange={handleChangeNewFileCategory}
                                labelWidth={80}>
                                <MenuItem value="">
                                    <em>Velg en kategori</em>
                                </MenuItem>
                                {fileCategories && fileCategories.map(row => (
                                    <MenuItem key={row.fileCategoryId} value={row.fileCategoryId}>{row.category}</MenuItem>
                                ))}
                            </Select>
                        </FormControl><br/><br/>
                        <Button variant="contained" onClick={() => {
                            setFilesForSaving(initialFilesForSavingState);
                        }}>Avbryt</Button>&nbsp;
                        <Button variant="contained" color="primary" onClick={() => saveFiles(offer.offerId)}>Legg til</Button>
                    </div>
                </Collapse>
                <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                    <Toolbar>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs>
                                <h3>Vedlegg</h3>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" className={classes.addUser} style={disableFields ? {display: "none"}:{}} onClick={() => {
                                    setFilesForSaving({...filesForSaving, isAddingFiles: !filesForSaving.isAddingFiles});
                                }}>
                                    Legg til vedlegg
                                </Button>
                                {isAdmin && <IconButton className={classes.deviationBtn} onClick={() => openDeviationModal(offer.offerId, 4)}>
                                    <ErrorIcon />
                                </IconButton>}
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                    <DeviationNotice deviations={offer?.deviations} deviationType={4} openCloseDeviationModal={openCloseDeviationModal} disableFields={disableFields} />

                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell algin="left">Navn</TableCell>
                                <TableCell align="left">Kategori</TableCell>
                                <TableCell align="left">Opplastet av</TableCell>
                                <TableCell align="left">Opplastet dato</TableCell>
                                <TableCell align="left">&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {files &&
                            files.map(row => (
                                <TableRow className={ classes.itemRow } key={row.fileId}>
                                    <TableCell align="left"><a target="_blank" href={encodeURI('/api/file/'+row.viewToken+'/'+row.filename.replace('.','_'))} rel="noopener noreferrer">{row.filename}</a></TableCell>
                                    <TableCell align="left">{row.fileCategory.category}</TableCell>
                                    <TableCell align="left">{row.registredByUser.fullname}</TableCell>
                                    <TableCell align="left"><Moment>{row.registeredDate}</Moment></TableCell>
                                    <TableCell aling="right" width={10}>
                                        {(offer.offerStatusId === 1 || offer.offerStatusId === 9) &&
                                        <IconButton aria-label="delete" onClick={(e) => deleteFile(e, row.viewToken)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                    <br/>

                </div>
            </Paper>

            {(offer?.offerStatusId === 1 || offer?.offerStatusId === 9) &&
            <Paper className={classes.paper}>
                <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                    <Toolbar>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs>
                                <h3>Innlevering</h3>
                            </Grid>
                            <Grid item>

                            </Grid>

                        </Grid>
                    </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                    {((offer?.refPersonName || '').length === 0 || (offer?.refPersonEmail || '').length === 0) ? (<><Alert severity="info">Referanseperson er påkrevd.</Alert><br/></>) : null}
                    {(files || []).find(x=>x.fileCategoryId === 1) === undefined ? (<><Alert severity="info">Brukerhåndbok er påkrevd.</Alert><br/></>) : null}
                    {equipments && (equipments[0].equipmentNr || '') === '' ? (<><Alert severity="info">Produktnummer er påkrevd.</Alert><br/></>) : null}
                    {equipments && ((equipments[0].maxHs || '') === '' && equipments[0].fixedMounting === true) ? (<><Alert severity="info">Maks bølge er påkrevd på fastmontert utstyr.</Alert><br/></>) : null}
                    {equipments && ((equipments[0].maxVc || '') === '' && equipments[0].fixedMounting === true) ? (<><Alert severity="info">Maks strøm er påkrevd på fastmontert utstyr.</Alert><br/></>) : null}
                    {(offer?.readGuidlines === false) ? (<><Alert severity="info">Standarden må leses og forstås før produktet kan sendes inn til godkjenning.</Alert><br/></>) : null}
                    
                    <Grid container className={classes.checkListItem}>
                        <Grid item xs={9}>
                            Er produktet risikovurdert
                        </Grid>
                        <Grid item xs={3} style={{textAlign: 'right'}}>
                            <ToggleButtonGroup size="small" exclusive onChange={handleChangeCheckListItem("riskEvaluated")}>
                                <ToggleButton className={classes.toggleButton} value="1" selected={offer?.riskEvaluated === true} disabled={disableFields}>
                                    Ja
                                </ToggleButton>
                                <ToggleButton className={classes.toggleButton} value="0" selected={offer?.riskEvaluated === false} disabled={disableFields}>
                                    Nei
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.checkListItem}>
                        <Grid item xs={9}>
                            Har du lest og forstått standarden?
                        </Grid>
                        <Grid item xs={3} style={{textAlign: 'right'}}>
                            <ToggleButtonGroup size="small" exclusive onChange={handleChangeCheckListItem("readGuidlines")}>
                                <ToggleButton className={classes.toggleButton} value="1" selected={offer?.readGuidlines === true} disabled={disableFields}>
                                    Ja
                                </ToggleButton>
                                <ToggleButton className={classes.toggleButton} value="0"  selected={offer?.readGuidlines === false} disabled={disableFields}>
                                    Nei
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.sendBtn}>
                        <Grid item xs>

                        </Grid>
                        <Grid item>
                            <br/>
                            <Button variant="contained" disabled={
                                (offer?.refPersonName || "").length === 0 || 
                                (offer?.refPersonEmail || "").length === 0 ||
                                offer?.riskEvaluated === null ||
                                offer?.readGuidlines === null ||
                                offer?.readGuidlines === false ||
                                (files || []).find(x=>x.fileCategoryId === 1) === undefined ||
                                equipments && equipments[0].equipmentNr === '' ||
                                equipments && equipments[0].equipmentNr === '' ||
                                equipments && ((equipments[0].maxHs || '') === '' && equipments[0].fixedMounting === true) ||
                                equipments && ((equipments[0].maxHs || '') === '' && equipments[0].fixedMounting === true) ||
                                equipments && ((equipments[0].maxVc || '') === '' && equipments[0].fixedMounting === true)
                            } color="primary" onClick={() => {handleDeliverOffer(offer.offerId)}}>Send produkt til godkjenning</Button>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
            }

            <Paper className={classes.paper}>
                <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                    <Toolbar>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs>
                                <h3>Mangellapper</h3>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell algin="left">Id</TableCell>
                                <TableCell algin="left">Title</TableCell>
                                <TableCell align="left">Description</TableCell>
                                <TableCell align="left">Type</TableCell>
                                <TableCell align="left">Status</TableCell>
                                <TableCell align="left">Lukket av</TableCell>
                                <TableCell align="left">Lukket dato</TableCell>
                                <TableCell align="left">Kommentar</TableCell>
                                <TableCell align="left">Registrert av</TableCell>
                                <TableCell align="left">Registrert dato</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(offer?.deviations || []).map((row,index) => (
                                <TableRow className={ classes.itemRow } key={index}>
                                    <TableCell align="left">{row.deviationId}</TableCell>
                                    <TableCell align="left">{row.title}</TableCell>
                                    <TableCell align="left">{row.description}</TableCell>
                                    <TableCell align="left">{row.deviationType.title}</TableCell>
                                    <TableCell align="left">{row.deviationStatus.title}</TableCell>
                                    <TableCell align="left">{row.closedByUser?.fullname}</TableCell>
                                    <TableCell align="left">{row.closedDate ? (<Moment>{row.closedDate}</Moment>) : ""}</TableCell>
                                    <TableCell align="left">{row.closedComment}</TableCell>
                                    <TableCell align="left">{row.registeredByUser?.fullname}</TableCell>
                                    <TableCell align="left"><Moment>{row.registeredDate}</Moment></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </Paper>
            {isAdmin &&
                <>
                    <OfferEvaluationList evaluationItems={(offer.offerEvaluations || [])} createNewEvaluation={createNewEvaluation}/>
                    <OrganizationDeclarations organizationId={(offer.organizationId || null)} />
                </>
            }
            <OfferLog offerHistory={offer?.offerHistory || []}/>

        </div>
    );
    
};

function mapDispatchToProps(dispatch) {
    return {
        offerActions: bindActionCreators(offerActions, dispatch),
        equipmentActions: bindActionCreators(equipmentActions, dispatch),
    }
}

function mapStateToProps(state) {
    return {
        stateOffer: state.offer,
        equipment: state.equipment
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Offer);